import types from './mutationTypes';
import decompress from '@/utility/decompress';
import {forEach, indexOf, map, replace} from 'lodash';
import {getData, postData} from '@/api';
import EventBus from '@nsftx/games-sdk-js/src/utility/eventBus';
import ticketFormater from '@/utility/ticketFormater';
import {createHeaders, createQueryParams} from '@/api/utility';
export default {
  toggleInfoModal({ commit }) {
    commit(types.TOGGLE_INFO_MODAL);
  },
  updateActiveEvent({ commit }, payload) {
    commit(types.UPDATE_ACTIVE_EVENT, payload);
  },
  setGamesInfoActive({ commit }, payload) {
    commit(types.SET_GAMES_INFO_ACTIVE, payload);
  },
  setIsVisualizationReady({ commit }, payload) {
    commit(types.SET_IS_VISUALIZATION_READY, payload);
  },
  handleSocketMessage({ commit, dispatch, state, getters}, rawPayload) {
    // Send relevant messages only if the visualisation is ready
    const messesagesToBounce = ['stopBetting', 'startCountdown', 'showResults', 'showVideos']
    const shouldSendMessage = ({ eventName }) =>
       messesagesToBounce.includes(eventName) && getters.isVisualizationReady

    if (shouldSendMessage(rawPayload)) {
      document.getElementById('visualization').contentWindow.postMessage(rawPayload, '*')
    }

    let timezone;
    console.log(rawPayload);
    const payload = rawPayload.compressed
      ? { ...rawPayload, data: decompress(rawPayload.data) }
      : rawPayload;
    switch (payload.eventName) {
      case 'stopBetting': {
        commit(types.SET_OFFER, payload.data.offer);
        dispatch('getLastTickets');
        const offerEventsId = map(payload.data.offer, (item) => item.eventId);
        forEach(getters['gamesBetslip/ticket'], (bet) => {
          if (indexOf(offerEventsId, bet.eventId) < 0) {
            dispatch('gamesBetslip/updateBet', {
              ...bet,
              locked: true,
              permanentlyLock: true,
            });
          }
        });
      }
      break;
      case 'TicketUpdate':
        EventBus.$emit('ResetTicketCheckSetTimeout', payload.data);
      break;
      // gateway
      case 'User.AuthorizationChanged':
        try {
          if (payload.data.auth.user?.settings?.oddType) {
            dispatch('setOddType', payload.data.auth.user.settings.oddType);
          }
          dispatch('setTpToken', payload.data.auth.tpToken || '');
          const user = payload.data.auth.token ? payload.data.auth.user : null;
          timezone = payload.data.auth.token && user.timezone
            ? user.timezone
            : state.config.timezone;
          if (timezone) {
            if (timezone !== state.timezone) {
              dispatch('setLoaderStatus', true);
              commit('SET_TIME_ZONE', timezone);
            }
          }
          if (user) {
            dispatch('setUser', user.id);
            dispatch('user/updateUser', {
              auth: {
                token: payload.data.auth.token,
                tpToken: payload.data.auth.tpToken,
              },
              profile: {
                ...user,
                logged: true,
              },
              isLoggedIn: true,
            });
            dispatch('user/setUserBalance', user.balance);
            dispatch('getLastTickets');
          } else {
            commit('gamesBetslip/SET_PLAYER_TICKETS', []);
            dispatch('user/updateUser', {
              auth: null,
              profile: null,
            });
            dispatch('user/setUserBalance', 0);
            // dispatch('setTicketPreviewData', null);
            // dispatch('setAppTicketHistoryActive', false);
            // commit('SET_LAST_TICKET_ACTIVE', false);
            // commit('CHANGE_USER_FAVOURITES', []);
          }
        } catch (error) {
          console.log('error', 'User.AuthorizationChanged error', error);
        }
        break;
      case 'User.BalanceChanged':
        dispatch('user/setUserBalance', payload.data.balance);
        break;
      case 'Widget.SettingsChanged':
        if (state.oddType !== payload.data.oddType) {
          dispatch('setOddType', payload.data.oddType);
        }
        break;
      case 'Tickets.Checked':
        // eslint-disable-next-line no-case-declarations
        const ticket = ticketFormater(payload.data.ticket);
        console.log('Tickets.Checked', ticket, payload.data.ticket);
        commit('betslip/SET_TICKETS_HISTORY', [ticket]);
        break;
      case 'Tickets.FetchHistory':
        console.log('Tickets.FetchHistory', payload.data);
        dispatch('getTicketsHistory', payload.data.params);
        break;
      case 'Tickets.ClickedOutside':
        console.log('Tickets.ClickedOutside', payload);
        break;
      // no default
    }
  },
  setIsBetslipOpen({ commit }, payload) {
    commit(types.SET_IS_BETSLIP_OPEN, payload);
  },
  setOddType: ({ commit }, payload) => {
    commit(types.SET_ODD_TYPE, payload);
  },
  setTpToken: ({ commit }, payload) => {
    commit(types.SET_TP_TOKEN, payload);
  },
  setUser: ({ state }, payload) => {
    if (payload) {
      if (window.busService) {
        window.busService.addChannel('Player', payload);
      }
      // if (payload && process.env.NODE_ENV === 'production') {
      //   setUser(payload, state.config.deviceId);
      // }
    } else {
      if (state.user && state.busService) {
        state.busService.removeChannel('Player', state.user.id);
      }
      // if (process.env.NODE_ENV === 'production') {
      //   removeUser();
      // }
    }
    // commit(types.SET_USER, payload);
  },
  setUserBalance: ({ commit }, payload) => {
    commit(types.SET_USER_BALANCE, payload);
  },
  // eslint-disable-next-line no-unused-vars
  payinTicket: ({ state, dispatch }, payload) => {
    const url = `${process.env.VUE_APP_ADD_TICKET}${payload.ticketRequestData.metadata.requestUuid}`;
    return postData(url, payload.ticketRequestData, payload.headers);
  },
  checkTicket: ({ state }, payload) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'HTTP-X-SEVEN-CLUB-UUID': state.config.tenantId,
        'HTTP-X-NAB-DP': 'web',
        authorization: `Bearer ${state.user.user.profile.token}`,
      };
      const url = `${process.env.VUE_APP_CHECK_TICKET_AFTER_PAYIN}${payload}`;
      return getData(url, headers);
    } catch (e) {
      return e;
    }
  },
  getLastTickets: async ({ state, commit }) => {
    try {
      commit('gamesBetslip/SET_IS_LAST_TICKETS_IN_PROGRESS', true);
      const headers = {
        'Content-Type': 'application/json',
        'X-Nsft-Seven-Tenant-Uuid': state.config.tenantId,
        authorization: `Bearer ${state.user.user.profile.token}`,
      };
      const url = `${process.env.VUE_APP_LAST_TICKETS}${state.user.user.profile.id}`;
      const { data } = await getData(url, headers);
      if (data.length) {
        const tickets = map(data, (ticket) => {
          return ticketFormater(ticket);
        });
        commit('gamesBetslip/SET_PLAYER_TICKETS', tickets);
      }

    } catch (e) {
      return e;
    } finally {
      commit('gamesBetslip/SET_IS_LAST_TICKETS_IN_PROGRESS', false);
    }
  },
  getTicketsHistory: async ({ state, getters, commit }, payload) => {
    try {
      commit(types.TOGGLE_HISTORY_LOADER, true);
      const { tenantId, language } = getters;
      const start = payload.start || payload.timeFrom;
      const end = payload.end || payload.timeTo;
      const queryData = {
        page: payload.currentPage || 1,
        count: payload.count || 50000,
        startDate: replace(start, ' ','*'),
        endDate: replace(end, ' ','*'),
        playerUuid: state.user.user.profile.id,
        ticket_delivery_platform: 'Web',
      };
      console.log('queryData', queryData);
      const headers = {
        authorization: `Bearer ${state.user.user.profile.token}`,
        'HTTP-X-NAB-TOKEN': tenantId,
        'HTTP-X-SEVEN-COMPANY-UUID': tenantId,
      };
      const headerData = {
        language,
        uuid: tenantId,
      };
      const url = `${process.env.VUE_APP_TICKET_HISTORY}${createQueryParams(queryData)}`;
      const { data } = await getData(url, createHeaders(headerData, headers));
      console.log('getTicketsHistory', data);
      if (data?.tickets.length) {
        const tickets = map(data.tickets, (ticket) => {
          return ticketFormater(ticket);
        });
        commit('betslip/SET_TICKETS_HISTORY', tickets);
        window.busService.sendMessage({
          action: 'Tickets.HistoryFetched',
          data: {
            params: {
              totalCount: data.totalCount,
            },
          },
        })
      } else {
        commit('betslip/SET_TICKETS_HISTORY', []);
      }

    } catch (e) {
      return e;
    } finally {
      commit(types.TOGGLE_HISTORY_LOADER, false);
    }
  },
  toggleTicketHistory({ commit }, payload) {
    commit(types.TOGGLE_TICKET_HISTORY, payload);
  },
};
