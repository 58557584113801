<script setup>
import LastTicketsBetItem from '@nsftx/games-sdk-js/src/components/BetslipArea/LastTickets/LastTicketsBetItem';
import {defineProps, toRefs} from '@vue/compat';
const props = defineProps({
  ticketsHistory: {
    type: Array,
    default: () => [],
  },
  detailsMode: {
    type: String,
    default: 'standalone',
  },
});
const {
  detailsMode,
  ticketsHistory,
} = toRefs(props);
</script>

<template>
  <div>
    <div
      v-for="ticket in ticketsHistory"
      :key="ticket.id"
      class="ticket-history-item"
    >
      <LastTicketsBetItem
        :ticket="ticket"
        :show-footer="false"
        :received-is-last-tickets-data="false"
        :details-mode="detailsMode"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.ticket-history-item {
  margin-bottom: 2px;

  ::v-deep(.last-tickets-item) {
      height: 100%;
      .body {
        .bet-item {
          height: 100%;
        }
      }
    }
  }

.ticket-history-item:last-child {
  margin-bottom: 0;
}
</style>
