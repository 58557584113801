import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'floating-vue/dist/style.css';

store.state.isLoggedIn = true;
store.state.isAndroid = navigator.userAgent.includes('wv');

window.addEventListener('Bus:Message', (event) => {
  store.dispatch('handleSocketMessage', event.detail);
});
window.addEventListener('Configuration:SetConfiguration', (e) => {
  console.log('Configuration:SetConfiguration', e);
  if (e.detail.user) {
    store.dispatch('setUser', e.detail.user.id);
    store.dispatch('user/updateUser', {
      auth: {
        token: e.detail.user.token,
        tpToken: e.detail.user.tpToken,
      },
      profile: {
        ...e.detail.user,
        logged: true,
      },
      isLoggedIn: true,
    });
    store.dispatch('user/setUserBalance', e.detail.user.balance);
  }
});

createApp(App).use(router).use(store).mount('#app');
