import { inflate } from 'pako';

export default function (encodedString) {
  // Decode the base64 string back into a byte array
  const decodedByteArray = Uint8Array.from(atob(encodedString), (c) => c.charCodeAt(0));

  // Decompress the byte array using pako
  const decompressedString = inflate(decodedByteArray, { to: 'string' });

  return JSON.parse(decompressedString);
}
