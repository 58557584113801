<template>
  <div id="app">
    <TemplateDefault v-if="appIsReady" />
    <CircleLoader v-if="loader" />
    <TicketsHistory v-if="isLoggedIn" />
  </div>
</template>

<script setup>
import applyPalette from '@nsftx/games-sdk-js/src/utility/applyPalette';
import CircleLoader from '@nsftx/games-sdk-js/src/components/CircleLoader';
import {computed, onBeforeMount, onBeforeUnmount, ref} from 'vue';
import { useStore } from 'vuex';
import TemplateDefault from '@/layouts/TemplateDefault';
import { init, initResize, removeResize } from '@/utility';
import gamesStatic from '@nsftx/games-sdk-js/src/api/gamesStatic';
import {assign} from 'lodash';
import i18n from '@/i18n';
import EventBus from '@nsftx/games-sdk-js/src/utility/eventBus';
import TicketsHistory from '@/components/TicketHistory/TicketsHistory';

const store = useStore();
const appIsReady = ref(null);
const loader = computed(() => store.getters.isLoaderActive);
const isLoggedIn = computed(() => store.getters['user/isLoggedIn']);
const setTheme = () => {
  const {
    config: { baseColor, theme },
  } = store.getters.ui;
  const rootElement = document.documentElement;
  const bodyElement = document.body;

  applyPalette.set(store.getters.ui[baseColor], rootElement);
  applyPalette.set(store.getters.ui[theme], bodyElement);
};

onBeforeMount(async () => {
  initResize(store);
  appIsReady.value = await init(store);
  if (store.getters.config) {
    setTheme();
  }
  const translations = await gamesStatic.getTranslations(store.getters.config?.locale || 'en');
  const translationsApp = (await i18n(store.getters.config?.locale || 'en')).default;
  await store.dispatch('setTranslations', assign(translations, translationsApp)); // sdk action
  EventBus.$on('openTicketHistory', () => {
    store.dispatch('toggleTicketHistory', true);
  });
});
onBeforeUnmount(() => {
  removeResize();
});
</script>

<style lang="scss">
@use './styles/globals';

html,
body {
  background: var(--background-1);
  color: var(--text-primary-1);
  font-size: 14px;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html,
body,
#app {
  height: 100%;
}
.tab-button {
  > button {
    text-transform: capitalize;
  }
  @media screen and (min-width: 660px){
    max-width: 190px !important;
  }
}
</style>
