import getParams from '@/utility/getParams';
import types from '@/store/mutationTypes';
import { getConfigService, busService } from '@/utility';

const init = async (store) => {
  const params = getParams();
  store.commit(types.SET_PARAMS, params);
  let isReady;
  try {
    const config = await getConfigService(store);
    await store.commit(types.SET_CONFIG, config);
    await store.commit(types.SET_OFFER, config.state.eventsOffer);
    window.busService = await busService(config, store);
    const betslipConfig = {
      betLayout: 'B',
      channel: config.applicationName,
      ticketTypes: ['single', 'combo'],
      rules: config.rules.rules,
      taxes: config.taxes,
      isPossibleWinActive: false,
      isPaymentBetAllowed: true,
      isPaymentPerBetAllowed: true,
      isFuturePerBetAllowed: false,
      isFuturePerTicketAllowed: false,
      isTicketCancelAllowed: false,
      isTicketRebetAllowed: false,
      minIncrement: 0.01,
    };
    await store.dispatch('gamesBetslip/setConfig', betslipConfig);
    isReady = true;
  } catch (e) {
    console.log('error', e);
    isReady = false;
  }
  return isReady;
};

export default init;
