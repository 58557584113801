import { filter } from 'lodash';

export default {
  queryParametars: (state) => state.queryParametars,
  screenSize: (state) => state.screenSize,
  isAudioOn: (state) => state.isAudioOn,
  isVisualizationReady: (state) => state.isVisualizationReady,
  config: (state) => state.config,
  language: (state) => state.config.locale,
  tenantId: (state) => state.config.tenantId,
  translations: (state) =>  state.translations,
  translation: (state) => (key) =>  state.translations[key] || key,
  offer: (state) => state.offer,
  offerSpecification: (state) => state.config.state.offer,
  ui: (state, getters) => getters.config.ui,
  theme: (state, getters) => getters.ui.config.theme,
  activeEventId: (state) => state.activeEventId,
  gamesInfoActive: (state) => state.gamesInfoActive,
  activeMatch: (state) => {
    let activeMatch = null;
    if (state.activeEventId) {
      activeMatch = filter(state.offer,
        (offer) => offer.eventIdToday === state.activeEventId)[0];
    } else {
      activeMatch = state.offer[0];
    }
    return activeMatch;
  },
  isMinimizeVisible: (state) => state.isMinimizeVisible,
  isBetslipOpen: (state) => state.isBetslipOpen,
  infoModalActive: (state) => state.infoModalActive,
  oddType: (state) => state.oddType,
  isLoaderActive: (state) => state.loader,
  user: (state) => state.user,
  tpToken: (state) => state.tpToken,
  nextRoundProp: (getters) => (key) => getters.offer[0][key],
  isAndroid: (state) => state.isAndroid,
  activeTicketHistory: (state) => state.activeTicketHistory,
  activeHistoryLoader: (state) => state.activeHistoryLoader,
};
