import { ConfigurationService, registry } from '@nsftx/games-config';
import has from 'lodash/has';
import { defaultTo } from 'lodash';
import throwError from '@/utility/throwError';

const getProductName = (name) => {
  let productName = name;
  if (process.env.VUE_APP_ENVIRONMENT === 'development') {
    productName = `${productName}Dev`;
  }
  return productName;
}

const getProperSlaveIdName = () => {
  let slaveId = 'VirtualSoccer';
  const path = window.location.pathname;
  switch (path) {
    case '/terminal-results/':
      slaveId = 'PluginVirtualSoccerResults';
      break;
    case '/backoffice-tickets-list/':
      slaveId = 'PluginVirtualSoccerTicketsHistory';
      break;
    default:
      slaveId = window.location.pathname === '/tickets-history/'
        || window.location.pathname === '/ticket-details/' ? 'PluginVirtualSoccer' : 'VirtualSoccer';
      break;
  }
  return slaveId;
};

const getConfigService = async (store) => {
  let config;
  const tenantId = defaultTo(store.getters.queryParametars.companyId,
    store.getters.queryParametars.tenantId);
  if (process.env.VUE_APP_ENVIRONMENT === 'development' || tenantId === 'b99752b3-443c-4c80-b559-945a95c4b805') {
    registry.httpAdapters.VSoccerHttpAdapter.prototype.getEndpointHeaders = function () {
      return {
        'X-Nsft-ProductId': '39d3bea9-0aa2-4aca-974b-7b9429d646e6',
      };
    }
  }
  try {
    /**
     * Instance of config service
     */
    const { clientPlatform } = store.getters.queryParametars;
    const allowDebug = tenantId === 'b99752b3-443c-4c80-b559-945a95c4b805'
      && !!store.getters.queryParametars.debug;
    let applicationName;
    if (store.getters.isTerminal) {
      applicationName = 'Terminal';
    } else {
      applicationName = clientPlatform || 'Web';
    }
    const configurationDataPrepare = {
      requiredPaths: [
        'productId',
        'tenantId',
        'locale',
      ],
      messageProtocol: 'gravityGateway',
      clientAdapters: [
        {
          name: 'UrlAdapter',
        },
        {
          name: 'GravityGatewayAdapter',
          options: {
            slaveId: getProperSlaveIdName(),
            gameId: getProductName('VirtualSoccer'),
            isDebug: allowDebug,
          },
        },
      ],
      ui: tenantId,
      environment: process.env.VUE_APP_ENV === 'production' ? 'production' : 'staging',
      platformName: 'seven',
      productName: getProductName('VirtualSoccer'),
      applicationName,
    };
    console.log('configurationDataPrepare', configurationDataPrepare);
    const configService = await new ConfigurationService(configurationDataPrepare);
    /**
     * Get config
     */
    setTimeout(() => {
      if (store.getters.isAndroid && has(window, 'WebAppListener')) {
        // eslint-disable-next-line no-undef
        WebAppListener.sendMessage('Slave.Init');
      }
    }, 200);
    config = await configService.getConfiguration();

    if (!config) throwError('Please try later no config data');

  } catch (error) {
    console.log('error', error);
  }
  return config;
};

export default getConfigService;
