import types from '@/store/mutationTypes';

const resize = (store) => {
  window.addEventListener('resize', () => {
    store.commit(types.SET_SCREEN_SIZE, window.innerWidth);
    store.commit(types.SET_GAMES_INFO_ACTIVE, window.innerWidth >=660);
  })
};
const initResize = (store) => {
  removeResize();
  store.dispatch('setDeviceLayout');
  store.commit(types.SET_SCREEN_SIZE, window.innerWidth);
  store.commit(types.SET_GAMES_INFO_ACTIVE, window.innerWidth >=660);
  resize(store);
};
const removeResize = () => {
  window.removeEventListener('resize', resize);
};

export {
  resize,
  initResize,
  removeResize,
};
