export default {
  queryParametars: {},
  config: null,
  theme: 'dark',
  palette: 'default',
  isGateway: 'default',
  screenSize: null,
  gamesInfoActive: false,
  isAudioOn: false,
  translations: {},
  offer: null,
  activeEventId: 0,
  isMinimizeVisible: false,
  isBetslipOpen: true,
  oddType: 1,
  timezone: null,
  user: null,
  userBalance: null,
  loader: true,
  isVisualizationReady: false,
  infoModalActive: false,
  isAndroid: false,
  activeTicketHistory: false,
  activeHistoryLoader: false,
  ticketsHistory: [],
};
