import {
  forEach,
} from 'lodash';

/**
 Prepare bets for ticket
 */
const prepareBets = (betsData) => {
  const bets = [];
  forEach(betsData, (bet) => {
    bets.push({
      eventId: bet.roundNumber,
      marketId: bet.marketId,
      outcomeType: bet.outcomeType,
      outcomeOdd: +bet.odds,
      outcomeCode: bet.outcomeCode,
      stake: bet.stake,
    });
  });
  return bets;
};

/**
 * Here we will prepare ticket for platform
 *
 * @param data {object}
 * @param cpvUuid {string}
 * @param reqUuid {string}
 * @returns {{metadata: {product, sources: [{firstName: string|*, lastName: string|*,
 * type: string, uuid: *, username: *}], requestUuid, paymentMethod: string, cpvUuid,
 * deliveryPlatform: string}, ticket: {ticketOddsOptions: number, payin: (null|boolean|string|*),
 * ticketCombinationGroups: [], ticketType: (number|*), bets: []}}}
 */
const makeTicketData = (data, cpvUuid, reqUuid) => {
  const bets = prepareBets(data.bets);
  const uuid = data.user.user.profile.id;
  return {
    metadata: {
      product: data.product,
      deliveryPlatform: data.deliveryPlatform,
      cpvUuid,
      paymentMethod: 'VirtualMoney',
      requestUuid: reqUuid,
      sources: [
        {
          type: 'player',
          uuid,
          username: data.user.user.profile.email,
          firstName: data.user.user.profile.firstName,
          lastName: data.user.user.profile.lastName,
        },
      ],
    },
    ticket: {
      bets,
      payin: data.payin,
      ticketCombinationGroups: [],
      ticketType: data.betslipType,
      ticketOddsOptions: 2,
    },
  };
};

/**
 * Create request uuid of 36 characters
 * @returns {string}
 */
const makeRequestUuid = () => {
  // Create part of uuid
  // return 4 character
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

const prepareTicketRequest = (data) => {
  const gameUuid = data.config.productId;
  const reqUuid = makeRequestUuid();
  return makeTicketData(data, gameUuid, reqUuid);
};

export { prepareTicketRequest, prepareBets };
