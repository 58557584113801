<template>
  <div class="tickets-history">
    <TicketsHistoryFilter
      v-if="route.name !== 'ticketsHistory'"
      :date-from="dateFrom"
      :date-to="dateTo"
      :details-mode="detailsMode"
      :locale="locale"
      @date-range="setDateRange"
    />
    <div class="tickets-list-wrapper">
      <TicketsHistoryList
        :tickets-history="ticketsHistory"
        :details-mode="detailsMode"
      />
    </div>
    <div class="notification-wrapper">
      <NotificationSDK
        :notification="notificationMessage"
        :use-store="false"
      />
    </div>
    <CircleLoader v-if="activeHistoryLoader" />
  </div>
</template>

<script setup>
import {defineProps, toRefs} from '@vue/compat';
import {computed, ref} from 'vue';
import {endOfDay, format, startOfDay} from 'date-fns';
import {useStore} from 'vuex';
import TicketsHistoryFilter from './TicketsHistoryFilter';
import TicketsHistoryList from './TicketsHistoryList';
import CircleLoader from '@nsftx/games-sdk-js/src/components/CircleLoader';
import NotificationSDK from '@nsftx/games-sdk-js/src/components/Notifications/Notification';
import {useRoute} from 'vue-router';

const store = useStore();
const route = useRoute();
const props = defineProps({
  detailsMode: {
    type: String,
    mode: 'standalone',
    default: 'standalone',
  },
  locale: {
    type: String,
    default: 'en',
  },
});
const { detailsMode, locale } = toRefs(props);
const notification = ref({
  value: '',
  status: '',
});
const dateFrom = ref(startOfDay(new Date()));
const dateTo = ref(endOfDay(new Date()));
const dateFormat = ref('yyyy-MM-dd HH:mm:ss');
const ticketsHistory = computed(() => store.getters['betslip/ticketsHistory']);
const activeHistoryLoader = computed(() => store.getters.activeHistoryLoader);
const setDateRange = ({ start, end }) => {
  dateFrom.value = start;
  dateTo.value = end;
  const historyDate = {
    start: formatDate(start),
    end: formatDate(end),
  };
  console.log('setDateRange', start, end);
  console.log('route', route);
  store.dispatch('getTicketsHistory', historyDate);
};
const formatDate = (date) => {
  return format(date, dateFormat.value);
};
const notificationMessage = computed(() => {
  let message = {};
  if (ticketsHistory.value && ticketsHistory.value.length) {
    message = {
      value: '',
      status: '',
    }
  } else {
    message = {
      value: notification.value.value || store.getters.translation('general_warning_no_data'),
      status: notification.value.status || 'neutral',
    }
  }
  return message;
});

</script>

<style scoped lang="scss">
::v-deep(.vc-pane-container) {
  font-family: 'Roboto',sans-serif !important;
}
.tickets-history {
  position: relative;
  color: var(--text-primary-1);
  font-size: var(--font-size-medium, 14px);
  height: 100%;
  min-height: 290px;
  z-index: 1;

  .tickets-list-wrapper {
    clear: both;

    .scrollable > * {
      max-height: calc(100vh - 500px);
    }
  }
  .notification-wrapper {
    width: 100%;
    margin-top: 7px;
    clear: both;
    position: absolute;
    bottom: 0;
  }
}
@media all and (max-width: 420px) {
  .tickets-history {
    .tickets-list-wrapper {
      .scrollable > * {
        max-height: calc(100vh - 200px);
      }
    }
  }
  .notification-wrapper {
    bottom: 20px;
  }
}
</style>
