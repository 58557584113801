import mutationTypesGames from '@nsftx/games-sdk-js/src/store/mutationTypes';
import {assign} from 'lodash';

const mutationTypesApp = {
  SET_PARAMS: 'SET_PARAMS',
  SET_CONFIG: 'SET_CONFIG',
  SET_SCREEN_SIZE: 'SET_SCREEN_SIZE',
  TOGGLE_AUDIO: 'TOGGLE_AUDIO',
  SET_TRANSLATIONS: 'SET_TRANSLATIONS',
  UPDATE_ACTIVE_EVENT: 'UPDATE_ACTIVE_EVENT',
  SET_GAMES_INFO_ACTIVE: 'SET_GAMES_INFO_ACTIVE',
  SET_OFFER: 'SET_OFFER',
  SET_IS_BETSLIP_OPEN: 'SET_IS_BETSLIP_OPEN',
  SET_ODD_TYPE: 'SET_ODD_TYPE',
  SET_TP_TOKEN: 'SET_TP_TOKEN',
  SET_TIME_ZONE: 'SET_TIME_ZONE',
  SET_USER: 'SET_USER',
  SET_USER_BALANCE: 'SET_USER_BALANCE',
  SET_IS_VISUALIZATION_READY: 'SET_IS_VISUALIZATION_READY',
  TOGGLE_INFO_MODAL: 'TOGGLE_INFO_MODAL',
  TOGGLE_TICKET_HISTORY: 'TOGGLE_TICKET_HISTORY',
  TOGGLE_HISTORY_LOADER: 'TOGGLE_HISTORY_LOADER',
  SET_TICKETS_HISTORY: 'SET_TICKETS_HISTORY',
};

const mutationTypes = assign(mutationTypesGames, mutationTypesApp);

export default mutationTypes;
