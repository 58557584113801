export default function (ticket) {
  const bets = ticket.bets.map((bet) => {
    const formattedBet = {
      id: bet.eventId,
      status: bet.status,
      round: bet.eventId,
      market: `${bet.outcomeName} (${bet.outcomeType})`,
      outcome: bet.eventName,
      system: null,
      stake: bet.stake,
      odd: bet.outcomeOdd,
    };

    return formattedBet;
  });

  return {
    id: ticket.id,
    payout: ticket.payout,
    payin: ticket.payin,
    payinTax: ticket.payinTaxAmount,
    payoutTax: ticket.payoutTax,
    superBonus: 0,
    createdAt: ticket.createDateTime,
    status: ticket.status,
    maxPossibleWin: ticket.maxPossibleWinnings,
    bets,
  };
}
