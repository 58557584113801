import types from './mutationTypes';

const setLanguage = (lang) => (lang === 'sr' ? 'sr-Latn' : lang);

export default {
  [types.SET_PARAMS](state, payload) {
    if (payload.lang) {
      // eslint-disable-next-line no-param-reassign
      payload.lang = setLanguage(payload.lang);
    }
    state.theme = payload.theme || 'dark';
    state.palette = payload.palette || 'default';
    state.isGateway = !!payload.gateway;
    state.queryParametars = payload;
  },
  [types.SET_CONFIG](state, payload) {
    console.log('config', payload);
    state.config = payload;
    state.config.taxes = {
      "payin": {
        "policy": false,
        "value": false,
        "hideTax": true
      },
      "payout": {
        "policy": false,
        "value": false,
        "hideTax": true
      }
    };
  },
  [types.SET_OFFER](state, payload) {
    state.offer = payload;
    console.log('SET_OFFER', state.offer);
  },
  [types.SET_TRANSLATIONS](state, payload) {
    state.translations = payload;
  },
  [types.SET_SCREEN_SIZE](state, payload) {
    state.screenSize = payload;
    state.isMinimizeVisible = payload >= 660 && payload < 960;
    state.isBetslipOpen = payload < 660 || payload >= 960;
  },
  [types.TOGGLE_AUDIO](state) {
    state.isAudioOn = !state.isAudioOn;
  },
  [types.TOGGLE_INFO_MODAL](state) {
    state.infoModalActive = !state.infoModalActive;
  },
  [types.UPDATE_ACTIVE_EVENT](state, payload) {
    state.activeEventId = payload;
  },
  [types.SET_GAMES_INFO_ACTIVE](state, payload) {
    state.gamesInfoActive = payload;
  },
  [types.SET_IS_BETSLIP_OPEN](state, payload) {
    state.isBetslipOpen = payload;
  },
  [types.SET_IS_VISUALIZATION_READY](state, payload) {
    state.isVisualizationReady = payload;
  },
  [types.SET_ODD_TYPE](state, payload) {
    state.oddType = payload;
  },
  [types.SET_TP_TOKEN](state, payload) {
    state.tpToken = payload;
  },
  [types.SET_TIME_ZONE](state, payload) {
    state.timezone = payload;
  },
  [types.TOGGLE_TICKET_HISTORY](state, payload) {
    state.activeTicketHistory = payload;
  },
  [types.TOGGLE_HISTORY_LOADER](state, payload) {
    state.activeHistoryLoader = payload;
  },
};
